.button {
  width: 100%;
  height: 100%;
  padding: var(--spacing-xs);
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 130%;
  margin: auto;
  border: 2px solid transparent;
  transition: 0.3s;
  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background: var(--accent-1) !important;
    color: var(--light-text);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }

  .loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dark {
  background: var(--dark-background);
  color: var(--light-text);
  &:hover {
    color: var(--light-text);
  }
}

.light {
  background: var(--light-background) !important;
  border: 1px solid var(--dark-background);
  color: var(--dark-text);
  &:hover {
    color: var(--light-text);
  }
}
.translucentLight {
  background: hsla(0, 0%, 100%, 0.6) !important;
  color: var(--dark-text);
  &:hover {
    opacity: 0.85;
  }
}

.bright {
  background: var(--accent-1) !important;
  color: var(--light-text);
  &:hover {
    opacity: 0.85;
  }
}

.translucentBright {
  background: var(--accent-1-translucent) !important;
  color: var(--light-text);
  &:hover {
    opacity: 0.9;
  }
}

.transparent {
  background: transparent !important;
  color: var(--light-text);
}

.transparentBorder {
  background: transparent !important;
  color: var(--light-text);
  border: 2px solid var(--dark-background);
}

.transparentBorderLight {
  background: transparent !important;
  color: var(--light-text);
  border: 2px solid var(--light-background);
}

.transparentBorderWhite {
  background: var(--light-background) !important;
  color: var(--dark-text);
  border: 2px solid var(--dark-background);
}
